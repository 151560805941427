const BlackboxData = {
  "Code": "IK_BLACKBOX",
  "Description": "Blackbox is an all-in-one mastering system that combines a simple interface with powerful track mastering capabilities.",
  "Label": "Ian Knowles",
  "Permalink": "blackbox",
  "Title": "Blackbox II Audio Mastering",
  "Type": "PROGRAM",
  "Downloads": {
    "OSX": "https://ianknowles.blob.core.windows.net/distribution/Blackbox.2.1.5.dmg",
    "WinX64": "https://ianknowles.blob.core.windows.net/distribution/Blackbox.2.1.5.zip",
    "WinX32": "https://ianknowles.blob.core.windows.net/distribution/Blackbox.2.1.5.zip"
  }
};

const SummitData = {
  "Code": "IK_PLUGIN_SUMMIT",
  "Description": "Summit is an advanced mastering grade limiter plugin VST / AU",
  "Label": "Ian Knowles",
  "Permalink": "summit",
  "Title": "Summit Limiter Plugin VST / AU",
  "Type": "PLUGIN",
  "Downloads": {
    "OSX": "https://ianknowles.blob.core.windows.net/distribution/Summit.1.0.0.osx.zip",
    "WinX64": "https://ianknowles.blob.core.windows.net/distribution/Summit.1.0.0.win.zip",
    "WinX32": ""
  }
};

const DonationData = {
  "Code": "IK_THANKS",
  "Description": "A thank you donation for the music produced.",
  "Label": "Ian Knowles",
  "Permalink": "thanks-for-the-music",
  "Title": "Ian Knowles - Thanks for the Music Donation",
  "Type": "DONATION"
};

export { BlackboxData, SummitData, DonationData };