import { Api } from './api';

export class IKService {
  static get_product(code) {
    return Api.request({
      method: 'GET',
      url: `${Api.get_base()}/api/GetProducts?code=${code}`,
    });
  }
  static checkout(args) {
    return Api.request({
      method: 'POST',
      url: `${Api.get_base()}/api/Checkout`,
      data: args
    });
  }
  static contact(args) {
    return Api.request({
      method: 'POST',
      url: `${Api.get_base()}/api/SendContact`,
      data: args
    });
  }
}