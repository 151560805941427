import * as React from "react"
import { IKService } from '../services/ik_service'

var currencyToSymbol = (currency) => {
  if(currency === 'GBP') {
    return '&pound;';
  }
  if(currency === 'EUR') {
    return '&euro;';
  }
  if(currency === 'USD') {
    return '$';
  }
  return 'UNK';
}

const isBrowser = typeof window !== "undefined"

class PriceBox extends React.Component {

  constructor(props) {
    super(props);
    var currency = null;
    if(isBrowser) {
      window.localStorage.getItem('APP_CURRENCY');
    }
    if(!currency) { currency = 'GBP'; }
    this.state = { product_code: props.product_code, loading:true, currency }
  }

  componentDidMount () {
    IKService.get_product(this.state.product_code).then(data => {      
      console.log(data);
      this.setState({product: data[0], loading: false, currency: this.state.currency});
    }).catch(err => {});
  }

  setCurrency(currency) {
    if(isBrowser) {
      window.localStorage.setItem('APP_CURRENCY', currency);
    }
    this.setState({currency});
  }

  checkout() {
    if(isBrowser) {
      window.location.href = `/checkout?code=${this.state.product_code}&currency=${this.state.currency}`;
    }
  }

  render() {
    if (this.state.loading) return (<p className="text-center">loading...</p>)

    const htmlSymbol = currencyToSymbol(this.state.currency);
    const price = this.state.product.price[this.state.currency].value.toFixed(2);

    return (
      <div className="price-box">
      <div className="price-info">
        <span className="app-currency-symbol" dangerouslySetInnerHTML={{__html: htmlSymbol}} /> <span>{price}</span>
      </div>
      <span data-app-product-code={this.state.product.code} className="buy-button" onClick={this.checkout.bind(this)} onKeyUp={this.checkout.bind(this)} role="button" tabIndex={0}>BUY</span>
      <ul className="currency-list">
        <li><span onClick={() => this.setCurrency('GBP')} className="currency-button">GBP</span></li>
        <li><span onClick={() => this.setCurrency('USD')} className="currency-button">USD</span></li>
        <li><span onClick={() => this.setCurrency('EUR')} className="currency-button">EUR</span></li>
      </ul>
    </div>
    )
  }
}

export { PriceBox }