import * as React from "react"
import { Layout } from "../components/layout"
import { Helmet } from 'react-helmet'
import { SummitData } from "../products";
import { PriceBox } from "../components/price-box";

const Title = SummitData.Title;
const Description = SummitData.Description;
const heroImageStyle={
  maxWidth: '600px',
  width:'100%'
};

const SummitPage = () => {
  return (
    <Layout>
      <Helmet defer={false}>
        <title>{Title}</title>
        <meta name="description" content={Description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={Title} />
        <meta property="og:description" content={Description} />
        <meta property="og:image" content="https://ianknowles.com/content/summit/IanKnowles-Summit-Limiter.png" />
      </Helmet>
      <section className="hero">
        <div className="container">
          <h1 className="text-center">Summit Limiter</h1>
          <p className="text-center">{Description}</p>
          <p className="text-center">
            <img src="/content/summit/IanKnowles-Summit-Limiter.png" alt="Summit Limiter - Ian Knowles" style={heroImageStyle} />
          </p>
          <div className="top-spacer text-center">
            <p>Download current verion 1.0.0</p>
            <a href={SummitData.Downloads.WinX64} className="download-button"><i className="n-icon">&#xf325;</i> Windows</a>
            <a href={SummitData.Downloads.OSX} className="download-button"><i className="n-icon">&#xf179;</i> Mac OSX</a>
          </div>
        </div>
      </section>
      <section className="alt">
        <div className="container">
          <h2>{Title}</h2>
          <PriceBox product_code={SummitData.Code} />
        </div>
      </section>
      <section className="dark">
        <div className="container">
          <h2>What is Summit</h2>
          <p>
            Summit is a mastering grade limiter plugin supporting VST2 / VST3 and AU versions with independantly adjustable envelopes for peaks and stereo
            It features a tube modeller to give a more natural analog feel and has some advanced metering which gives track volumes as Peak, RMS, LUFs, Range and Integrated loudness
          </p>
          <p>The limiter can be used:</p>
          <ul>
            <li>On the master output for mastering</li>
            <li>On a mix bus / channel bus to tame peaks</li>
            <li>Creatively on individual channels</li>
            <li>Or as a limiter to balance entire mixes or live performances</li>
          </ul>
          <p><strong>Key Features</strong></p>
          <ul>
            <li>Independent Peak and Stereo Envelopes</li>
            <li>Multi tube modes including Single, Dual and Broken</li>
            <li>Adaptive Release</li>
            <li>A,B,C,D Quick Swapping</li>
            <li>Advanced Metering </li>
            <li>Loudness and Waveform views</li>
          </ul>
          <p><strong>Compatibility</strong></p>
          All of our plugins work with Windows (&gt;8.1) and Mac (&gt;10.11), and are compatible with 64-bit VST and AU.
        </div>
      </section>
      <section className="alt">
        <div className="container">
          <h2>Installation</h2>
          <p>
            <strong>Windows</strong> - download the windows zip file and extract files.
          </p>
          <ul>
            <li>For VST2 copy "IK Summit.dll" into your VstPlugins folder e.g. C:\Program Files\Steinberg\VstPlugins</li>
            <li>For VST3 copy "IK Summit.vst3" into your VST3 folder e.g. C:\Program Files\Common Files\VST3</li>
          </ul>
          <p><strong>Mac</strong> - download the OSX zip file and extract files.</p>
          <ul>
            <li>For VST2 copy "IK Summit.vst" into your VstPlugins folder e.g. <i>/Library/Audio/Plug-Ins/VST/</i></li>
            <li>For VST3 copy "IK Summit.vst3" into your VST3 folder e.g. <i>/Library/Audio/Plug-Ins/VST3/</i></li>
            <li>For AU copy "IK Summit.component" into your AU Components folder e.g. <i>/Library/Audio/Plug-Ins/Components/</i></li>
          </ul>
          <div className="top-spacer text-center">
            <p><strong>Download Summit Limiter!</strong></p>
            <a href={SummitData.Downloads.WinX64} className="download-button"><i className="n-icon">&#xf325;</i> Windows</a>
            <a href={SummitData.Downloads.OSX} className="download-button"><i className="n-icon">&#xf179;</i> Mac OSX</a>
            <p style={{marginTop:'1em'}}>
                Windows Plugins <a href={SummitData.Downloads.WinX64}>Summit.win.zip</a><br />
                Mac OSX Plugins <a href={SummitData.Downloads.OSX}>Summit.osx.zip</a>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default SummitPage