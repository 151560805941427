const cookieName = "IK";

export class Api {

  static get_cookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  };

  static set_cookie(name, value, mins) {
    const d = new Date();
    // d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    d.setTime(d.getTime() + 60 * 1000 * mins);
    document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
  };

  static delete_cookie(name) { Api.set_cookie(name, '', -1); };

  static refresh_cookie() {
    const cookie = Api.get_cookie(cookieName);
    if (cookie) {
      Api.set_cookie(cookieName, cookie, 15);
    };
  }

  static me(authCallback, passThru) {  
    if(window.userUpdateCallback !== undefined)
    {      
      window.userUpdateCallback (authCallback, passThru);
    }
  }

  static init() {
    try {
      window.api_token = Api.get_cookie(cookieName);
      this.me();
    } catch (e) { }
  }

  static set_auth_token(token, authCallback, passThru) {
    window.api_token = token;
    try {
      // window.localStorage.setItem(cookieName, token);
      Api.set_cookie(cookieName, token, 15);
    } catch (e) { }


    this.me(authCallback, passThru);
  }

  static clear_token() {
    window.api_token = null;
    try {
      // window.localStorage.removeItem(cookieName);
      Api.delete_cookie(cookieName);
    } catch (e) { }
  }

  static get_base() {
    return 'https://ianknowles-api.azurewebsites.net';
  }

  static set_base(args) {
    window.api_base = args.replace(/\/$/, '');
  }

  static get_token_data() {
    const token = window.api_token;
    if (token !== null) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
          })
          .join('')
      );
      return JSON.parse(jsonPayload);
    }
    return {};
  }

  static get_auth_token() {
    return window.api_token;
  }

  static request(args) {
    return new Promise(
      function (resolve, reject) {
        if (args.method === 'GET') {
          const data = this.check_for_data(args.url);
          if (data !== null) {
            return resolve(data);
          }
        }
        const xhr = new XMLHttpRequest();
        xhr.open(args.method, args.url);
        xhr.setRequestHeader('Accept', 'application/json');

        if (args.hasOwnProperty('authCallback') && args.authCallback) {
          xhr.authCallback = args.authCallback;
        }
        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            const data = JSON.parse(this.responseText);
            if (data.hasOwnProperty('auth')) {
              if (this.hasOwnProperty('authCallback') && this.authCallback) {
                Api.set_auth_token(data.auth, this.authCallback, data);
              } else {
                Api.set_auth_token(data.auth);
              }
            }
            resolve(data);
          } else {
            let response = {};
            try {
              response = JSON.parse(this.responseText);
            } catch (err) {
              console.log(err);
            }

            reject({
              status: this.status,
              statusText: xhr.statusText,
              responseText: this.responseText,
              data: response,
            });
          }
        }
        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };

        if (args.headers) {
          Object.keys(args.headers).forEach(function (key) {
            xhr.setRequestHeader(key, args.headers[key]);
          });
        }
        if (window.api_token !== undefined && window.api_token !== null) {
          xhr.setRequestHeader('Authorization', `Bearer ${window.api_token}`);
        }
        if (args.hasOwnProperty('download_progress')) {
          xhr.onprogress = args.download_progress;
        }
        if (args.hasOwnProperty('upload_progress')) {
          xhr.upload.addEventListener('progress', args.upload_progress, false);
        }

        let { params } = args;
        if (params && typeof params === 'object') {
          params = Object.keys(params)
            .map(function (key) {
              return (
                `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
              );
            })
            .join('&');
        }
        if (
          args.method === 'POST' ||
          args.method === 'PUT' ||
          (args.method === 'DELETE' && args.hasOwnProperty('data'))
        ) {
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.send(JSON.stringify(args.data));
        } else {
          xhr.send(params);
        }
      }.bind(this)
    );
  }

  static validate_email(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static check_for_data(url) {
    if (window.preloaded_data !== undefined) {
      if (window.preloaded_data.url === url) {
        console.log(`Preloaded data found: ${url}`);
        const response = window.preloaded_data.value;
        delete window.preloaded_data;
        return response;
      }
    }
    return null;
  }
}